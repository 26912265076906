/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  MultiSelectField,
  TextAreaField,
  VisualizationAttr,
  Spinner,
} from 'commons/components'
import {
  ALLOWED_PERMISSIONS,
  findAllowedPermission,
} from 'commons/constants/allowedPermission'
import cleanFormData from 'commons/utils/cleanFormData'

import updateStatusGoodsDonation from '../services/updateStatusGoodsDonation'

import { ToasterError } from 'commons/components'
import * as Layouts from 'commons/layouts'

const FormUpdateStatusDonasiBarang = ({ goodsDonation, statuses }) => {
  const { control, handleSubmit } = useForm({ defaultValues: goodsDonation })

  const navigate = useNavigate()

  const kirim = data => {
    const cleanData = cleanFormData(data)
    updateStatusGoodsDonation({
      ...cleanData,
    })
      .then(({ data: { data } }) => {
        navigate(`/mobile/goods/history`)
      })
      .catch(error => {
        console.error(error)
        toast.error(t => <ToasterError error={error} t={t} />)
      })
  }

  return (
    <Layouts.FormComponentLayoutMobileFirst
      title="Update Status Donasi Barang"
      onSubmit={handleSubmit(kirim)}
      vas={[
        <VisualizationAttr
          label="Nama Donatur"
          content={goodsDonation?.name}
        />,
        <VisualizationAttr label="Tanggal" content={goodsDonation?.date} />,
        <VisualizationAttr
          label="Jenis Barang"
          content={goodsDonation?.goodsName}
        />,
        <VisualizationAttr label="Jumlah" content={goodsDonation?.quantity} />,
      ]}
      formFields={[
        <Controller
          name="status"
          control={control}
          render={({ field, fieldState }) => (
            <SelectionField
              label="Status"
              options={statuses}
              placeholder="Masukkan status"
              fieldState={fieldState}
              defaultValue={goodsDonation.status}
              {...field}
              isRequired={false}
            />
          )}
        />,
      ]}
      itemsEvents={[
        <Button type="submit" variant="primary">
          Kirim
        </Button>,
      ]}
    />
  )
}

export default FormUpdateStatusDonasiBarang
