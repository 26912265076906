import React from 'react'

const ViewContainerButtonLayoutMobileFirst = ({ children }) => {
  return (
    <div className="flex flex-row flex-wrap justify-end gap-2 mt-4 mr-4">
      {children}
    </div>
  )
}

export default ViewContainerButtonLayoutMobileFirst
