/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  MultiSelectField,
  TextAreaField,
  VisualizationAttr,
  Spinner,
} from 'commons/components'
import {
  ALLOWED_PERMISSIONS,
  findAllowedPermission,
} from 'commons/constants/allowedPermission'
import cleanFormData from 'commons/utils/cleanFormData'

import saveViaPaymentGateway from '../services/saveViaPaymentGateway'

import { ToasterError } from 'commons/components'
import * as Layouts from 'commons/layouts'

const FormTambahPaymentGateway = ({}) => {
  const { control, handleSubmit } = useForm()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const kirim = data => {
    const cleanData = cleanFormData(data)
    saveViaPaymentGateway({
      ...cleanData,
      ...{ idprogram: id },
    })
      .then(({ data: { data } }) => {
        navigate(`/viapaymentgateway/list`)
      })
      .catch(error => {
        console.error(error)
        toast.error(t => <ToasterError error={error} t={t} />)
      })
  }

  return (
    <Layouts.FormComponentLayout
      title="Tambah PaymentGateway"
      onSubmit={handleSubmit(kirim)}
      vas={[]}
      formFields={[
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Name"
              placeholder="Masukkan name"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Email"
              placeholder="Masukkan email"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="phone"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Phone"
              placeholder="Masukkan phone"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="amount"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Amount"
              placeholder="Masukkan amount"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Description"
              placeholder="Masukkan description"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        ,
        //   <Controller
        //     name="title"
        //     control={control}
        //     render={({ field, fieldState }) => (
        // 	  <InputField
        //         label="Title"
        //         placeholder="Masukkan title"
        //         fieldState={fieldState}
        // 		{...field}
        // 		isRequired={false}
        //       />
        //     )}
        //   />
        <Controller
          name="vendor_name"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Vendor Name"
              placeholder="Masukkan vendor name"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Payment Method"
              placeholder="Masukkan payment method"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="retailOutlet"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Retail Outlet"
              placeholder="Masukkan retail outlet"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="bank"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Bank"
              placeholder="Masukkan bank"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="ewalletType"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="E-Wallet Type"
              placeholder="Masukkan e-wallet type"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="phone_number"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Phone Number"
              placeholder="Masukkan phone number"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="payment_type"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Payment Type"
              placeholder="Masukkan payment type"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="token_id"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Token ID"
              placeholder="Masukkan token id"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,

        // adiet ada tambahan 2 atribut baru ya
        // int quantity = ((Double) vmjExchange.getRequestBodyForm("quantity")).intValue();
        // int pricePerItem = ((Double) vmjExchange.getRequestBodyForm("price_per_item")).intValue();
      ]}
      itemsEvents={[
        <Button type="submit" variant="primary">
          Kirim
        </Button>,
      ]}
    />
  )
}

export default FormTambahPaymentGateway
