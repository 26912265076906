/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React, { useEffect, useState, useContext } from 'react'
import { Button, Spinner } from 'commons/components'
import * as Layouts from 'commons/layouts'
import { Link, useParams } from 'react-router-dom'
import { HeaderContext } from 'commons/components'
import isSelectedFeature from 'commons/utils/isSelectedFeature'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'commons/auth'
import ViaPaymentGatewayTable from '../components/ViaPaymentGatewayTable'

import getViaPGListElement from '../services/getViaPGListElement'
const DaftarPGPage = props => {
  const { checkPermission } = useAuth()

  const [isLoading, setIsLoading] = useState({
    tableViaPaymentGateway: false,
  })
  const { setTitle } = useContext(HeaderContext)

  const [viaPGListElement, setViaPGListElement] = useState()
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(prev => ({ ...prev, tableViaPaymentGateway: true }))
        const { data: viaPGListElement } = await getViaPGListElement()
        setViaPGListElement(viaPGListElement.data)
      } finally {
        setIsLoading(prev => ({ ...prev, tableViaPaymentGateway: false }))
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setTitle('Daftar PG Page')
  }, [])
  return (
    <Layouts.ViewContainerLayout
      buttons={
        <>
          <></>
        </>
      }
    >
      <Layouts.ListContainerTableLayout
        title={'Table ViaPaymentGateway'}
        singularName={'ViaPaymentGateway'}
        items={[viaPGListElement]}
        isLoading={isLoading.tableViaPaymentGateway}
      >
        <ViaPaymentGatewayTable viaPGListElement={viaPGListElement} />
      </Layouts.ListContainerTableLayout>
    </Layouts.ViewContainerLayout>
  )
}
export default DaftarPGPage
