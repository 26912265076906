/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React, { useEffect, useState, useContext } from 'react'
import { Button, Spinner } from 'commons/components'
import * as Layouts from 'commons/layouts'
import { Link, useParams } from 'react-router-dom'
import { HeaderContext } from 'commons/components'
import isSelectedFeature from 'commons/utils/isSelectedFeature'
import { useSearchParams } from 'react-router-dom'
import FormTambahPaymentGateway from '../components/FormTambahPaymentGateway'
const TambahPGPage = props => {
  const [isLoading, setIsLoading] = useState({
    tambahPaymentGateway: false,
  })
  const { setTitle } = useContext(HeaderContext)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setTitle('Tambah PG Page')
  }, [])
  return (
    <Layouts.ViewContainerLayout buttons={<></>}>
      <Layouts.FormContainerLayout singularName={'PaymentGateway'}>
        <FormTambahPaymentGateway {...props} />
      </Layouts.FormContainerLayout>
    </Layouts.ViewContainerLayout>
  )
}
export default TambahPGPage
