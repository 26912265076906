/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React, { useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Detail,
  VisualizationAttr,
  Modal,
  Spinner,
} from 'commons/components'

import * as Layouts from 'commons/layouts'

const BerhasilDonasi = ({ data }) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()

  return (
    <Layouts.DetailComponentLayoutMobileFirst
      item={data}
      itemsAttrs={[
        {
          id: 'bukalinkdibawahiniuntukmelakukanpembayaran',
          condition: '',
          label: 'Buka link di bawah ini untuk melakukan pembayaran',
          featureName: 'paymentLink',
        },
      ]}
      itemsEvents={[]}
      itemsModals={[]}
    />
  )
}

export default BerhasilDonasi
