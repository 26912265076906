/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import RequireAuth from 'commons/auth/RequireAuth'

import Home from './containers/Home'

const homeRoutes = [
  {
    path: '/',
    element: <Home />,
  },
]

export default homeRoutes
