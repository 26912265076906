/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, Modal } from 'commons/components'
import isSelectedFeature from 'commons/utils/isSelectedFeature'
import { isMobile } from 'commons/utils/responsive'

import * as Layouts from 'commons/layouts'

const DonasiTable = ({ donasiBarangListElement }) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()
  const detail = async donasiItem => {
    isMobile() && navigate(`/mobile/goods/history/${donasiItem.id}`)
  }

  return (
    <Layouts.ListComponentCardLayoutMobileFirst
      items={donasiBarangListElement}
      itemsAttrs={[
        {
          id: 'status',
          condition: 'isStatus',
          label: 'Status',
          featureName: 'status',
        },
        {
          id: 'invalid',
          condition: '',
          label: '',
          featureName: 'program',
        },
        {
          id: 'namaDonatur',
          condition: '',
          label: 'Nama Donatur',
          featureName: 'name',
        },
        {
          id: 'jumlah',
          condition: '',
          label: 'Jumlah',
          featureName: 'quantity',
        },
      ]}
      onClick={donasiItem => `/mobile/goods/history/${donasiItem.id}`}
    />
  )
}

export default DonasiTable
