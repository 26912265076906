import { useRoutes } from 'react-router-dom'

import { commonRoutes, commonMobileRoutes } from 'commons/routes.js'
import userRoutes from 'user/routes'
import roleRoutes from 'role/routes'
import staticPageRoutes from 'staticPage/routes'
import homeRoutes from 'home/routes'
import activityRoutes from 'activity/routes'
import incomeRoutes from 'income/routes'
import expenseRoutes from 'expense/routes'
import arusKasReportRoutes from 'arusKasReport/routes'
import viaPaymentGatewayRoutes from 'viaPaymentGateway/routes'
import manualConfirmationRoutes from 'manualConfirmation/routes'
import goodsRoutes from 'goods/routes'
import paymentGatewayRoutes from 'paymentGateway/routes'

const GlobalRoutes = () => {
  const router = useRoutes([
    ...commonRoutes,
    ...staticPageRoutes,
    ...userRoutes,
    ...roleRoutes,
    ...homeRoutes,
    ...activityRoutes,
    ...incomeRoutes,
    ...expenseRoutes,
    ...arusKasReportRoutes,
    ...paymentGatewayRoutes,
  ])
  return router
}

const MobileRoutes = () => {
  const router = useRoutes([
    ...commonMobileRoutes,
    ...viaPaymentGatewayRoutes,
    ...manualConfirmationRoutes,
    ...goodsRoutes,
  ])
  return router
}

export { GlobalRoutes, MobileRoutes }
