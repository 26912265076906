/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React, { useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Detail,
  VisualizationAttr,
  Modal,
  Spinner,
} from 'commons/components'

import * as Layouts from 'commons/layouts'

const DetailViaPaymentGateway = ({ data }) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()

  const allItemsAttrs = [
    { id: 'name', condition: '', label: 'Name', featureName: 'name' },
    { id: 'email', condition: '', label: 'Email', featureName: 'email' },
    { id: 'phone', condition: '', label: 'Phone', featureName: 'phone' },
    { id: 'date', condition: '', label: 'Date', featureName: 'date' },
    { id: 'amount', condition: '', label: 'Amount', featureName: 'amount' },
    {
      id: 'paymentMethod',
      condition: '',
      label: 'Payment Method',
      featureName: 'paymentMethod',
    },
    {
      id: 'description',
      condition: '',
      label: 'Description',
      featureName: 'description',
    },
    {
      id: 'idProgram',
      condition: '',
      label: 'ID Program',
      featureName: 'idProgram',
    },
    { id: 'title', condition: '', label: 'Title', featureName: 'title' },
    {
      id: 'vendorName',
      condition: '',
      label: 'Vendor Name',
      featureName: 'vendorName',
    },
    { id: 'status', condition: '', label: 'Status', featureName: 'status' },
    {
      id: 'paymentID',
      condition: '',
      label: 'Payment ID',
      featureName: 'paymentId',
    },
    {
      id: 'paymentLink',
      condition: '',
      label: 'Payment Link',
      featureName: 'paymentLink',
    },
    {
      id: 'eWalletLink',
      condition: '',
      label: 'E-Wallet Link',
      featureName: 'eWalletLink',
    },
    {
      id: 'vANumber',
      condition: '',
      label: 'VA Number',
      featureName: 'vaNumber',
    },
    { id: 'bankCode', condition: '', label: 'Bank', featureName: 'bankCode' },
    {
      id: 'retailPaymentCode',
      condition: '',
      label: 'Retail Payment Code',
      featureName: 'retailPaymentCode',
    },
    {
      id: 'paymentCheckoutURL',
      condition: '',
      label: 'Payment Checkout URL',
      featureName: 'paymentCheckoutUrl',
    },
    {
      id: 'invoiceTransactionToken',
      condition: '',
      label: 'Invoice Transaction Token',
      featureName: 'invoiceTransactionToken',
    },
    {
      id: 'debitCardRedirectURL',
      condition: '',
      label: 'Debit Card Redirect URL',
      featureName: 'debitCardRedirectUrl',
    },
    {
      id: 'creditCardRedirectURL',
      condition: '',
      label: 'Credit Card Redirect URL',
      featureName: 'creditCardRedirectUrl',
    },
  ]

  // Filter the itemsAttrs array based on the existence of data
  const filteredItemsAttrs = allItemsAttrs.filter(
    attr => data[attr.featureName] !== ''
  )

  return (
    <Layouts.DetailComponentLayout
      item={data}
      itemsAttrs={filteredItemsAttrs}
      itemsEvents={[]}
      itemsModals={[]}
    />
  )
}

export default DetailViaPaymentGateway
