/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React, { useEffect, useState, useContext } from 'react'
import { Button, Spinner } from 'commons/components'
import * as Layouts from 'commons/layouts'
import { Link, useParams } from 'react-router-dom'
import { HeaderContext } from 'commons/components'
import isSelectedFeature from 'commons/utils/isSelectedFeature'
import { useSearchParams } from 'react-router-dom'
import FormTambahProgram from '../components/FormTambahProgram'
const TambahProgramDonasi = props => {
  const [isLoading, setIsLoading] = useState({
    tambahProgram: false,
  })
  return (
    <Layouts.ViewContainerLayoutMobileFirst
      pageName="Tambah Program Donasi"
      backRoute={`/mobile/donation`}
      buttons={<></>}
    >
      <Layouts.FormContainerLayoutMobileFirst singularName={'Program'}>
        <FormTambahProgram {...props} />
      </Layouts.FormContainerLayoutMobileFirst>
    </Layouts.ViewContainerLayoutMobileFirst>
  )
}
export default TambahProgramDonasi
