/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React, { useEffect, useState, useContext } from 'react'
import { Button, Spinner } from 'commons/components'
import * as Layouts from 'commons/layouts'
import { Link, useParams } from 'react-router-dom'
import { HeaderContext } from 'commons/components'
import isSelectedFeature from 'commons/utils/isSelectedFeature'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'commons/auth'
import PemasukanTable from '../components/PemasukanTable'

import getIncomeListElement from '../services/getIncomeListElement'
const TablePemasukanPage = props => {
  const { checkPermission } = useAuth()

  const [isLoading, setIsLoading] = useState({
    tablePemasukan: false,
  })
  const { setTitle } = useContext(HeaderContext)

  const [incomeListElement, setIncomeListElement] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(prev => ({ ...prev, tablePemasukan: true }))
        const { data: incomeListElement } = await getIncomeListElement()
        setIncomeListElement(incomeListElement.data)
      } finally {
        setIsLoading(prev => ({ ...prev, tablePemasukan: false }))
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setTitle('Table Pemasukan Page')
  }, [])
  return (
    <Layouts.ViewContainerLayout
      buttons={
        <>
          <Layouts.ViewContainerButtonLayout>
            {checkPermission('CreateIncome') && (
              <Link to={`/income/tambah`}>
                {' '}
                <Button className="p-2" variant="primary">
                  Tambah Pemasukan
                </Button>
              </Link>
            )}
          </Layouts.ViewContainerButtonLayout>
        </>
      }
    >
      <Layouts.ListContainerTableLayout
        title={'Table Pemasukan'}
        singularName={'Pemasukan'}
        items={[incomeListElement]}
        isLoading={isLoading.tablePemasukan}
      >
        <PemasukanTable incomeListElement={incomeListElement} />
      </Layouts.ListContainerTableLayout>
    </Layouts.ViewContainerLayout>
  )
}
export default TablePemasukanPage
